@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.tooltip {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    &:hover &__text {
        visibility: visible;
        opacity: 1;
    }
    &--top {
        top: -10px;
        left: 50%;
        transform: translate(-50%, -100%);

        &-mobile-left {
            @include mobile {
                left: 0;
                transform: translate(-80%, -100%);
                .tooltip__arrow {
                    left: 85%;
                }
            }
        }
    }
    &__arrow {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        border-width: 5px;
        border-style: solid;
        border-color: $black transparent transparent transparent;
        transition: all 0.3s ease-in-out;
    }
    &__text {
        //min-width: 100%;
        visibility: hidden;
        position: absolute;
        text-align: center;
        padding: 8px 16px;
        border-radius: 6px;
        @include fontStyle($fs: 14px, $fw: 600, $lh: 1.215);
        z-index: 1;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        background-color: $black;
        color: $white;
    }
}
