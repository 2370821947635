@import 'src/assets/styles/mixins';
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 0;
    z-index: 10;
    height: 72px;
    &--white {
        background: $white;
        border-bottom: 1px solid $greyLight;
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 18px;
        @include mobile {
            flex-wrap: wrap;
        }
        &_part {
            display: flex;
            &:first-child {
                flex: 1;
            }
            @include mobile {
                order: 1;
                &-search {
                    order: 2;
                    width: 100%;
                }
            }
            img {
                width: 24.3rem;
                height: auto;
                cursor: pointer;
                @include desktop {
                    width: 19rem;
                }
                @include mobile {
                    width: 115px;
                }
            }
        }
    }
    &--big {
        @include mobile {
            height: 125px;
        }
    }
}
