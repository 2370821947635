@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.auth-btn{
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid $grey200;
  border-radius: 16px;
  height: 64px;
  width: 100%;
  padding-left: 30px;
  img{
    height: 30px;
    margin-right: 17px;
  }
  div{
      @include fontStyle($Inter, 18px,1,600)
  }
}