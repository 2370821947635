@import 'src/assets/styles/variables';
.link-button {
    display: flex;
    align-items: center;
    padding: 4px 0;
    h5 {
        position: relative;
        color: $violet;
        font-weight: 500;
        line-height: 1;
        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background-color: $violet;
            transition: all 0.3s ease-in-out;
        }
        &:hover::after {
            width: 100%;
        }
    }
    & > span,
    & > svg {
        display: inline-flex;
        margin: 0 0 0 5px;
    }
    span {
        align-items: center;
        width: 24px;
        height: 24px;
    }
    &--s {
        padding: 8px 0;
        h5 {
            font-size: 16px;
        }
        span {
            width: 20px;
            height: 20px;
        }
    }
    &--simple {
        h5 {
            &:after {
                display: none;
            }
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        & > span,
        & > svg {
            margin: 0 5px 0 0;
        }
    }
    &:hover {
        opacity: 0.8;
    }
}
