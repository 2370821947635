@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';
.house {
    &__logo {
        padding: 10px 0;
        border-bottom: 1px solid $greyLight;
        box-shadow: 0 0 10px $greyLight;
        img {
            width: 35rem;
            height: auto;
            margin: 0 auto;
        }
    }
    &__header {
        padding: 16px 0 24px;
        @include mobile {
            padding: 16px 0;
        }
    }
    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 0 30px;
        &_item {
            width: calc((100% - 30px) / 2);
            @include mobile {
                width: 100%;
                order: 2;
            }
            &--map {
                border-radius: 16px;
                overflow: hidden;
                @include mobile {
                    height: 360px;
                    margin-bottom: 32px;
                    order: 1;
                }
            }
        }
        &--five {
            padding: 6rem 0;
            gap: 0;
            @include desktop {
                padding: 40px 0;
            }
            @include tablet {
                gap: 16px 0;
            }
            @include mobile {
                padding: 20px;
            }
            .house__grid_item {
                width: calc(100% / 5);
                padding-right: 10px;
                @include tablet {
                    width: calc(100% / 3);
                }
                @include mobile {
                    width: 100%;
                }
            }
        }
        &--nine {
            padding: 4.5rem 0;
            gap: 0;
            @include desktop {
                padding: 35px 0;
            }
            @include tablet {
                gap: 24px 0;
            }
            .house__grid_item {
                flex: 1;
                width: calc(100% / 8);
                padding-right: 10px;
                &--small {
                    flex: 0.7;
                    @include tablet {
                        flex: auto;
                    }
                }
                &--big {
                    flex: 1.3;
                    @include tablet {
                        flex: auto;
                    }
                }
                @include tablet {
                    width: calc(100% / 4);
                    flex: auto;
                }
                @include mobile {
                    width: 50%;
                }
            }
        }
        &--four {
            gap: 0 3.2rem;
            @include tabletMini {
                gap: 32px;
            }
            .house__grid_item {
                width: calc((100% - 9.6rem) / 4);
                @include tabletMini {
                    width: calc((100% - 32px) / 2);
                }
                @include mobile {
                    width: 100%;
                }
            }
            &-small {
                gap: 8px;
                .house__grid_item {
                    width: calc((100% - 24px) / 4);
                    height: 100px;
                    @include tabletMini {
                        width: calc((100% - 16px) / 3);
                    }
                    @include mobile {
                        width: 100%;
                        height: 90px;
                        order: initial;
                    }
                    &--full {
                        width: 100%;
                    }
                }
            }
        }
    }
    .bg-violet {
        border-top: 1px solid $grey300;
        border-bottom: 1px solid $grey300;
    }
    &__additional-details {
        padding: 6rem 0;
        @include desktop {
            padding: 40px 0;
        }
        @include mobile {
            padding: 20px;
        }
        h2 {
            margin-bottom: 3rem;
        }
    }
    &__all-info {
        display: flex;
        flex-direction: column;
        gap: 40px 0;
        padding: 40px 0;
        @include desktop {
            gap: 30px 0;
            padding: 30px 0;
        }

        .bg-white {
            padding: 40px;
            border-radius: 16px;
            border: 1px solid $greyLight;
            @include desktop {
                padding: 30px;
            }
            @include mobile {
                padding: 20px 10px;
            }
        }
        .bg-transparent {
            h2 {
                margin-bottom: 16px;
            }
        }
        &-block__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
                padding: 0 10px;
                gap: 16px 0;
            }
            &_part {
                display: flex;
                flex-wrap: wrap;
                gap: 0 32px;
                @include mobile {
                    width: 100%;
                    gap: 16px;
                }
            }
        }
    }
}
