@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.comments {
    padding: 40px 0;
    background-color: $violetLight;
    border-top: 1px solid $grey300;
    border-bottom: 1px solid $grey300;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        h2 {
            margin-bottom: 20px;
        }
        p {
            @include fontStyle($fs: 18px, $lh: 1.5);
            color: $black;
            &.error {
                @include fontStyle($fs: 14px, $lh: 1, $fw: 400);
                color: $red;
                @include mobile {
                    font-size: 12px;
                }
            }
        }
    }
    &__box {
        max-width: 1200px;
        border-radius: 16px;
        border: 1px solid $greyLight;
        background: $white;
        box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.02);
        padding: 24px;
        @include mobile {
            padding: 16px;
        }
    }
}

.comment {
    display: flex;
    gap: 0 2rem;
    @include mobile {
        gap: 0 12px;
    }
    &__pic {
        width: 6.4rem;
        height: 6.4rem;
        min-width: 6.4rem;
        min-height: 6.4rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        text-transform: uppercase;
        @include mobile {
            width: 4rem;
            height: 4rem;
            min-width: 4rem;
            min-height: 4rem;
        }
    }
    h6 {
        @include fontStyle($fs: 18px, $lh: 1.5, $fw: 500);
        color: $black;
        margin-bottom: 12px;
        @include mobile {
            font-size: 16px;
        }
        span {
            display: inline-flex;
            align-items: center;
            gap: 0 3px;
            margin-left: 8px;
            @include fontStyle($fs: 14px, $lh: 1.6, $fw: 400);
            color: $grey600;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            span {
                margin-left: 0;
            }
        }
    }
    &__message {
        width: 100%;
        @include fontStyle($fs: 14px, $lh: 1.6, $fw: 400);
        color: $black;
    }
    &__input {
        width: calc(100% - 8.4rem);
        border-radius: 16px;
        border: 1px solid $grey200;
        background: $greyLight;
        padding: 12px;
        &_footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;
            gap: 0 12px;
        }
        @include mobile {
            width: calc(100% - 52px);
        }

        textarea {
            background-color: transparent !important;
            border: none !important;
            padding: 0 !important;
            color: $black;
            &::placeholder {
                color: $grey;
            }
        }
    }
}
