@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.search-user {
    position: relative;
    width: 300px;
    @include mobile {
        width: 100%;
    }
    .main-input {
        padding: 0;
        height: 5rem;
        border-radius: 8px;
        @include mobile {
            height: 4.5rem;
        }

        input {
            font-size: 14px;
        }
    }
    &__select {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        width: 100%;
        z-index: 99;
        &_content {
            margin-top: 5px;
            background-color: $white;
            border: 1px solid $grey200;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            max-height: 200px;
            overflow-y: auto;
            &-item {
                display: flex;
                align-items: center;
                gap: 0 8px;
                padding: 8px 10px;
                font-size: 14px;
                line-height: 1.25;
                font-weight: 500;
                color: $black;
                cursor: pointer;
                &:hover {
                    background-color: $grey200;
                }
                &-picture {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3.5rem;
                    height: 3.5rem;
                    min-width: 3.5rem;
                    min-height: 3.5rem;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}
