@import 'src/assets/styles/variables';
.info-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    border-radius: 16px;
    min-height: 48px;
    &:nth-child(odd) {
        background-color: $violetLight;
    }
    h5 {
        color: $grey700;
    }
    h4 {
        font-weight: 600;
        text-align: right;
        padding-left: 10px;
    }
}
